/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.flex-container {
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;

    justify-content: space-around;
}

@media all and (max-width: 800px) {
    .flex-container{
        justify-content: flex-start;
    }
}
.flex-item {
    width: 350px;
    margin-top: 5px;
    
   
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    line-height: 150 px;
}
a:link {
    
    background-color: transparent;
    text-decoration: none;
  } */